import React, { FunctionComponent } from "react";
import FAQ from "../../components/faq";
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const News: FunctionComponent = () => {
  return (
    <Layout className="page__faq">
      <FAQ />
    </Layout>
  );
};

export default News;

export const Head = () => {
  return (
    <SEO title="FAQs">
      <script type="application/ld+json">{JSON.stringify({})}</script>
    </SEO>
  );
};
