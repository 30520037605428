import { Link } from "gatsby";
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { AuthLink } from "../links";
import VenueInfo from "../venueinfo";

export const ArticleBar = styled.div`
  position: relative;
  width: 100%;
  background-color: #fff;
  color: #000;
  z-index: 0;
  margin-bottom: 0px;
  padding-top: 50px;
  height: 97%;
  .leftText {
    text-align: left;
  }
  .artImage {
    max-width: 400px;
    width: 100%;
    float: left;
    margin-right: 30px;
    min-height: 237px;
    max-height: 237px;
    margin-bottom: 10px;
  }
  div.artImage {
    float: none;
    display: inline-block;
    margin-bottom: 50px;
    padding-bottom: 60px;
  }
  .clearMe {
    clear: both;
  }
  .socialGrid {
    display: grid;
    grid-template-columns: 200px 1fr;
  }
  .purpHeader {
    font-family: "Gilroy-ExtraBold", sans-serif;
    font-size: 115px;
    color: #8733ff;
    letter-spacing: 1px;
    display: block;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 70px;
    margin-bottom: 100px;
  }
  .purpleMe {
    color: #7f35b2 !important;
    font-family: "RedHatText-Regular", sans-serif;
    font-weight: 400;
  }
  .posts,
  .impressions {
    font-size: 24px;
    line-height: 32px;
  }
  .posts .purpleMe,
  .impressions .purpleMe,
  .speakName {
    font-family: "halyard-display", sans-serif;
    font-weight: 600;
    font-size: 18px;
    color: #00bceb;
    text-transform: uppercase;
  }
  .speakTitle {
    color: #051526;
  }
  .articles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 40px;
    grid-row-gap: 70px;
    a {
      text-decoration: none;
    }
    max-width: 1200px;
    margin: 0 auto;
  }
  .wrapUp {
    line-height: 24px;
    max-width: 1200px;
    margin: 0 auto;
  }
  .articleWrap {
    max-width: 345px;
  }
  .articleImage {
    display: block;
    width: 100%;
    height: 194px;
  }
  .wrapVid {
    max-width: 100%;
    width: 400px;
    min-height: 280px;
    display: inline-block;
    margin-right: 30px;
    margin-bottom: 30px;
    vertical-align: top;
  }
  .innerVid {
    min-height: 280px;
  }
  .titleBlock {
    font-family: "Gilroy-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 24px;
    background-color: #8733ff;
    color: #fff;
    padding: 16px 22px;
    height: 75px;
  }
  @media only screen and (min-width: 1441px) and (max-width: 1600px) {
  }
  @media only screen and (min-width: 1200px) and (max-width: 1441px) {
    .wrapUp {
      max-width: 90%;
    }
  }
  @media only screen and (max-width: 1199px) and (min-width: 768px) {
    .wrapUp {
      max-width: 90%;
    }
    .articles {
      grid-template-columns: 1fr 1fr;
      max-width: 680px;
    }
    .purpHeader {
      max-width: 680px;
    }
    .articleImage {
      max-height: 180px;
    }
    .titleBlock {
      height: 100px;
    }
  }
  @media only screen and (min-width: 374px) and (max-width: 767px) {
    .wrapUp {
      max-width: 90%;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
    }
    .purpHeader {
      font-size: 40px;
      max-width: 345px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .wrapVid {
      margin-right: 0px;
    }
  }
  @media only screen and (min-width: 360px) and (max-width: 373px) {
    .wrapUp {
      max-width: 90%;
    }
    .wrapVid {
      margin-right: 0px;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .purpHeader {
      font-size: 35px;
      max-width: 325px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .articleImage {
      max-height: 180px;
    }
    .titleBlock {
      height: 100%;
    }
  }
  @media only screen and (min-width: 281px) and (max-width: 359px) {
    .wrapUp {
      max-width: 90%;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 345px;
      padding-left: 15px;
      padding-right: 15px;
    }
    .socialGrid {
      grid-template-columns: 100px 1fr;
    }
    .wrapVid {
      margin-right: 0px;
    }
    .purpHeader {
      font-size: 35px;
      max-width: 290px;
      padding-left: 15px;
      padding-right: 15px;
      margin-top: 0px;
      margin-bottom: 30px;
    }
    .articleImage {
      max-height: 150px;
    }
    .titleBlock {
      height: 100%;
    }
  }
  @media only screen and (min-width: 120px) and (max-width: 280px) {
    .wrapUp {
      max-width: 90%;
    }
    .wrapVid {
      margin-right: 0px;
    }
    .socialGrid {
      grid-template-columns: 100px 1fr;
    }
    .articles {
      grid-template-columns: 1fr;
      max-width: 260px;
      padding-left: 15px;
      padding-left: 15px;
    }
    .purpHeader {
      font-size: 15px;
      max-width: 260px;
      margin-bottom: 30px;
      margin-top: 0px;
      padding-left: 15px;
      padding-left: 15px;
    }
    .articleImage {
      max-height: 150px;
    }
    .titleBlock {
      height: 100%;
    }
  }
`;

const Articles = (props) => {
  return (
    <ArticleBar id="about-bg">
      <div className="wrapUp">
        <h1 className="titlehead">FAQs</h1>
        <div className="p-container">
          <h2>COVID-19 SAFETY</h2>
          <p>
            The health and safety of our community remains our top priority. Out
            of an abundance of caution for our guests and staff, we will require
            all attendees to:
          </p>
          <ul style={{ marginTop: "0px" }}>
            <li>
              Provide proof of full vaccination at time of badge pick-up
              (vaccination card or photo).
            </li>
            <li>
              If you are NOT fully vaccinated on the day of the event, you will
              need to provide a negative test taken within 48 hours of the
              event.
            </li>
            <li>
              Refusal to provide either document will result in your removal
              from the venue and forfeiture of your registration fee.
            </li>
          </ul>
          <h2>Face Masks</h2>
          <p className="no-space">
            Scoop News Group will be following any/all mask mandates by the
            local jurisdiction. Currently, masks are encouraged for all
            attendees but are not required.
          </p>
          <p>
            Scoop News Group will update this page with current mask protocols
            as we get closer to the event.
          </p>
          <h2>Other Safety Measures</h2>
          <p>
            Hand sanitizer will be provided, and additional cleaning measures
            will be taken prior to the event. Masks will be provided at the
            event for attendees. Food and drink will be served with your health
            and safety in mind.
          </p>
        </div>
      </div>
    </ArticleBar>
  );
};

const AllArticles = (props) => {
  return (
    <div className="articleWrap">
      <a href={props.url} target="_blank">
        <img src={props.image} alt={props.title} className="articleImage" />
        <div className="titleBlock">{props.title}</div>
      </a>
    </div>
  );
};

export default Articles;
